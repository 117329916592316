<template>
  <div class="home">
    <FsLightbox
      :toggler="toggler"
      :sources="currentImages"
      :slide="slideIndex"
      :onOpen="setSlide"
      :onClose="closeSlide"
    />

    <div class="layout" id="layout_14909">
      <div class="region layout-region video-section" id="layout_region_0">
        <!-- <div class="video-overlay"></div> -->
        <div class="full-width">
          <div class="style-105031 layout-row row-fluid">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="129"
                id="content_177612"
              >
                <div class="content externalvideo">
                  <div class="content-wrap bgvid-dimensions">
                    <video
                      playsinline=""
                      autoplay=""
                      muted=""
                      loop=""
                      id="bgvid"
                      style="width: 100%"
                    >
                      <source
                        type="video/mp4"
                        src="@/assets/video/myskool-elixir-cover.mp4"
                      />
                      <!-- src="@/assets/video/video-final.mp4" -->
                      <!-- <source type="video/mp4" src="https://cdn.cloudious.net/myskool-elixir-cover.mp4" /> -->
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="layout-row row-fluid">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="hidden-phone layout-block style-102833 clearfix"
                data-cid="389"
                id="content_177515"
              >
                <div class="content menu">
                  <nav
                    class="nav-menu menu-animate-true menu-action-hover menu-type-horizontal-flat menu-direction-down"
                  >
                    <ul class="menu-container level1">
                      <!-- <li class="menu-item menu-item-111158 l1-item level-1">
                        <a href="#"> Study Explore </a>
                      </li>
                      <li class="menu-item menu-item-111433 l1-item level-1">
                        <a href="#"> Student Life </a>
                      </li>
                      <li class="menu-item menu-item-111085 l1-item level-1">
                        <a href="#"> Parent Hub Journey </a>
                      </li>
                      <li class="menu-item menu-item-111578 l1-item level-1">
                        <a href="#"> About Us </a>
                      </li> -->
                      <li class="menu-item menu-item-111433 l1-item level-1">
                        <a href="#">Student Life</a>
                      </li>
                      <li class="menu-item menu-item-111085 l1-item level-1">
                        <a href="#">Parents</a>
                      </li>
                      <li class="menu-item menu-item-111085 l1-item level-1">
                        <a href="#">News & Publication</a>
                      </li>
                      <li class="menu-item menu-item-111578 l1-item level-1">
                        <a href="#">E Mart</a>
                      </li>
                      <!-- <li class="menu-item menu-item-111362 l1-item level-1">
                        <a href="boarding.html"> Boarding </a>
                      </li>
                      <li class="menu-item menu-item-111429 l1-item level-1">
                        <a href="community.html"> Community </a>
                      </li> -->
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="style-104895 region layout-region marquee-section marque-video"
        >
          <div class="full-width">
            <div class="layout-row row-fluid marquee-wrapper">
              <div class="marquee-content scroll">
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-country-100.png" alt="" />
                  <p class="icon-text">7 acres</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-ai-100.png" alt="" />
                  <p class="icon-text">AI Integrated</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-cctv-100.png" alt="" />
                  <p class="icon-text">24/7 CCTV</p>
                </div>
                <div class="marquee-item">
                  <img
                    src="@/assets/images/icons8-energy-saving-bulb-100.png"
                    alt=""
                  />
                  <p class="icon-text">Green Energy</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-bus-100.png" alt="" />
                  <p class="icon-text">Drive through pick & drop</p>
                </div>
                <div class="marquee-item">
                  <img
                    src="@/assets/images/icons8-faneuil-hall-100.png"
                    alt=""
                  />
                  <p class="icon-text">Purpose built facility</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-education-100.png" alt="" />
                  <p class="icon-text">Holistic education</p>
                </div>
                <div class="marquee-item">
                  <img
                    src="@/assets/images/icons8-leaderboard-100.png"
                    alt=""
                  />
                  <p class="icon-text">Indoor & Outdoor Sports arena</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-garden-100.png" alt="" />
                  <p class="icon-text">Organic Garden</p>
                </div>
              </div>
              <div class="marquee-content scroll">
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-country-100.png" alt="" />
                  <p class="icon-text">7 acres</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-ai-100.png" alt="" />
                  <p class="icon-text">AI Integrated</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-cctv-100.png" alt="" />
                  <p class="icon-text">24/7 CCTV</p>
                </div>
                <div class="marquee-item">
                  <img
                    src="@/assets/images/icons8-energy-saving-bulb-100.png"
                    alt=""
                  />
                  <p class="icon-text">Green Energy</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-bus-100.png" alt="" />
                  <p class="icon-text">Drive through pick & drop</p>
                </div>
                <div class="marquee-item">
                  <img
                    src="@/assets/images/icons8-faneuil-hall-100.png"
                    alt=""
                  />
                  <p class="icon-text">Purpose built facility</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-education-100.png" alt="" />
                  <p class="icon-text">Holistic education</p>
                </div>
                <div class="marquee-item">
                  <img
                    src="@/assets/images/icons8-leaderboard-100.png"
                    alt=""
                  />
                  <p class="icon-text">Indoor & Outdoor Sports arena</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-garden-100.png" alt="" />
                  <p class="icon-text">Organic Garden</p>
                </div>
              </div>
              <div class="marquee-content scroll">
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-country-100.png" alt="" />
                  <p class="icon-text">7 acres</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-ai-100.png" alt="" />
                  <p class="icon-text">AI Integrated</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-cctv-100.png" alt="" />
                  <p class="icon-text">24/7 CCTV</p>
                </div>
                <div class="marquee-item">
                  <img
                    src="@/assets/images/icons8-energy-saving-bulb-100.png"
                    alt=""
                  />
                  <p class="icon-text">Green Energy</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-bus-100.png" alt="" />
                  <p class="icon-text">Drive through pick & drop</p>
                </div>
                <div class="marquee-item">
                  <img
                    src="@/assets/images/icons8-faneuil-hall-100.png"
                    alt=""
                  />
                  <p class="icon-text">Purpose built facility</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-education-100.png" alt="" />
                  <p class="icon-text">Holistic education</p>
                </div>
                <div class="marquee-item">
                  <img
                    src="@/assets/images/icons8-leaderboard-100.png"
                    alt=""
                  />
                  <p class="icon-text">Indoor & Outdoor Sports arena</p>
                </div>
                <div class="marquee-item">
                  <img src="@/assets/images/icons8-garden-100.png" alt="" />
                  <p class="icon-text">Organic Garden</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="style-105032 region layout-region" id="layout_region_2">
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177997"
              >
                <div
                  class="layout_block_177997 region page-region"
                  id="page_region_175238"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="stories-section">
        <div class="container">
          <div class="stories-wrapper">
            <a
              class="stories-item-wrapper"
              v-for="(item, ind) in storiesData"
              :key="ind"
              @click="openStory(item.images, ind)"
            >
              <div class="stories-item">
                <img :src="item.thumbnail" alt="" class="stories-img" />
              </div>
              <p class="stories-title">{{ item.title }}</p>
            </a>
            <!-- <a class="stories-item-wrapper">
              <div class="stories-item">
                <img
                  src="@/assets/images/DSC00338.jpg"
                  alt=""
                  class="stories-img"
                />
              </div>
            </a>
            <a class="stories-item-wrapper">
              <div class="stories-item">
                <img
                  src="@/assets/images/DSC00359.jpg"
                  alt=""
                  class="stories-img"
                />
              </div>
            </a>
            <a class="stories-item-wrapper">
              <div class="stories-item">
                <img
                  src="@/assets/images/DSC00394.jpg"
                  alt=""
                  class="stories-img"
                />
              </div>
            </a> -->
          </div>
        </div>
      </div>

      <div class="style-105032 region layout-region" id="layout_region_1">
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177611"
              >
                <div
                  class="layout_block_177611 region page-region"
                  id="page_region_174824"
                >
                  <!-- <h1 class="h1-style page-title page-title-level-1">Study at The Elixir School</h1> -->
                  <div class="style-103676 page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img
                                src="@/assets/images/studyHeading.png"
                                alt=""
                              />
                              <!-- <img
                                src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/text/large_text1988760_89004.png"
                                alt=""
                              /> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col page-col span6 col"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block style-103672 clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <!-- https://sac.myschoolapp.com/app/detail/video/8684736 -->
                                <a
                                  href="#"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    src="@/assets/images/DSC00391.jpg"
                                    class="link-image"
                                    alt="Student 1"
                                  />
                                </a>
                                <!-- <a href="#" class="custom-link-photo style-no-select links-imglink">
                                  <img src="@/assets/images/study1.png" class="link-image" alt="Student 1" />
                                </a> -->
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-col span6 col"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block style-103672 clearfix"
                        data-cid="2"
                        id="content_3122165"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <!-- https://sac.myschoolapp.com/app/detail/video/8684735 -->
                                <a
                                  href="#"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    src="@/assets/images/DSC00394.jpg"
                                    class="link-image"
                                    alt="Student 2"
                                  />
                                </a>
                                <!-- <a href="#" class="custom-link-photo style-no-select links-imglink">
                                  <img src="@/assets/images/study2.png" class="link-image" alt="Student 2" />
                                </a> -->
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-col span6 col"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block style-103672 clearfix"
                        data-cid="2"
                        id="content_3122166"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <!-- https://sac.myschoolapp.com/app/detail/video/8684734 -->
                                <a
                                  href="#"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    src="@/assets/images/DSC00409.jpg"
                                    class="link-image"
                                    alt="Student 3"
                                  />
                                </a>
                                <!-- <a href="#" class="custom-link-photo style-no-select links-imglink">
                                  <img src="@/assets/images/study3.png" class="link-image" alt="Student 3" />
                                </a> -->
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="last-col page-col span6 col"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block style-103672 clearfix"
                        data-cid="2"
                        id="content_3122167"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <!-- https://sac.myschoolapp.com/app/detail/video/8684732 -->
                                <a
                                  href="#"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    src="@/assets/images/DSC00452.jpg"
                                    class="link-image"
                                    alt="Student 4"
                                  />
                                </a>
                                <!-- <a href="#" class="custom-link-photo style-no-select links-imglink">
                                  <img src="@/assets/images/study4.png" class="link-image" alt="Student 4" />
                                </a> -->
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="style-103644 region layout-region" id="layout_region_3">
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="style-103676 page-row row">
                    <div
                      class="first-col page-col span16 col other-video"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <div
                        class="page-block style-103675 clearfix"
                        data-cid="1"
                        id="content_3120486"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <!-- <div class="photos">
                              <img
                                src="@/assets/images/school.jpg"
                                alt=""
                                style="border-radius: 10px"
                              />
                            </div> -->
                            <video
                              playsinline=""
                              autoplay=""
                              muted=""
                              loop=""
                              id="bgvid"
                              style="width: 100%"
                            >
                              <source
                                type="video/mp4"
                                src="@/assets/video/final-2.mp4"
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="last-col page-col span8 col"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div
                        class="page-block style-103675 clearfix"
                        data-cid="1"
                        id="content_3121855"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img
                                src="@/assets/images/villagetitle.png"
                                alt=""
                              />
                              <!-- <img
                                src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/text/large_text1988757_89002.png"
                                alt=""
                              /> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="page-block style-103675 clearfix"
                        data-cid="2"
                        id="content_3118675"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <!-- cinematic-experience.html -->
                                <a
                                  href="#"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4652717_152390.png"
                                    class="link-image"
                                    alt="Cinematic Experience"
                                  />
                                </a>

                                <!-- cinematic-experience.html -->
                                <a class="custom-title links-urllink" href="#"
                                  >Cinematic Experience</a
                                >
                              </li>
                              <li class="group">
                                <!-- admission/take-a-tour.html -->
                                <a
                                  href="#"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4643419_152391.png"
                                    class="link-image"
                                    alt="Take a Tour"
                                  />
                                </a>

                                <!-- admission/take-a-tour.html -->
                                <a class="custom-title links-urllink" href="#"
                                  >Take a Tour</a
                                >
                              </li>
                              <li class="group">
                                <!-- admission/why-a-school-for-boys.html -->

                                <a
                                  href="https://www.google.com/maps/place/Elixir+School/@24.9324927,67.0477922,15z/data=!4m6!3m5!1s0x3eb33f783fe04e11:0x8c2b24dc7e3d5c3!8m2!3d24.9324927!4d67.0477922!16s%2Fg%2F11cs38t9sc?entry=ttu"
                                  target="_blank"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <!-- src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4976835_152389.png" -->
                                  <img
                                    src="@/assets/images/icons8-location-100.png"
                                    class="link-image"
                                    alt="Location"
                                  />
                                </a>
                                <!-- admission/why-a-school-for-boys.html -->
                                <a
                                  class="custom-title links-urllink"
                                  href="https://www.google.com/maps/place/Elixir+School/@24.9324927,67.0477922,15z/data=!4m6!3m5!1s0x3eb33f783fe04e11:0x8c2b24dc7e3d5c3!8m2!3d24.9324927!4d67.0477922!16s%2Fg%2F11cs38t9sc?entry=ttu"
                                  target="_blank"
                                  >Location</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="style-105032 region layout-region indi-section">
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="style-103676 page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img src="@/assets/images/celeb.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col style-103700 page-col span12 col"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <div class="my-content">
                              We believe that quality education should not be
                              confined to a particular geography, but rather
                              should be widespread. It is with this premise that
                              we have established The Elixir School in one of
                              Karachi’s oldest and well-established communities
                              – North Nazimabad.
                              <br />
                              <br />
                              Our mission is to inculcate a questioning mindset
                              in students, to tap into students’ multiple
                              intelligences via an integrated curriculum that
                              fosters love for learning, nature, diverse
                              cultures, languages, and extra-curricular
                              activities. Our students will actively seek
                              knowledge and solutions backed by strong values of
                              honesty, hard work and harmony with the
                              environment and community.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div class="">
                        <img src="@/assets/images/indi1.jpg" alt="" />
                      </div>
                      <div class="">
                        <img src="@/assets/images/indi2.jpg" alt="" />
                      </div>
                      <div class="">
                        <img src="@/assets/images/indi3.jpg" alt="" />
                      </div>
                      <div class="">
                        <img src="@/assets/images/indi4.jpg" alt="" />
                      </div>
                      <div class="">
                        <img src="@/assets/images/indi5.jpg" alt="" />
                      </div>
                      <div class="">
                        <img src="@/assets/images/indi6.jpg" alt="" />
                      </div>
                      <div class="">
                        <img src="@/assets/images/indi7.jpg" alt="" />
                      </div>
                      <div class="">
                        <img src="@/assets/images/indi8.jpg" alt="" />
                      </div>
                      <div class="">
                        <img src="@/assets/images/DSC00394.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="style-104895 region layout-region marquee-section other-icons"
      >
        <div class="full-width">
          <div class="layout-row row-fluid marquee-wrapper">
            <div class="marquee-content scroll reverse-scroll">
              <div class="marquee-item">
                <img src="@/assets/images/icon1.svg" alt="" />
                <p class="icon-text">Cambridge Checkpoints</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon2.png" alt="" />
                <p class="icon-text">Essential Learner Enrichment</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon3.png" alt="" />
                <p class="icon-text">Extended Learner Development</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon4.png" alt="" />
                <p class="icon-text">Elixir Buzz</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon5.png" alt="" />
                <p class="icon-text">Student Governance System</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon6.png" alt="" />
                <p class="icon-text">Curriculum & Pedagogy</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon7.png" alt="" />
                <p class="icon-text">Collaborations & Partnerships</p>
              </div>
            </div>
            <div class="marquee-content scroll reverse-scroll">
              <div class="marquee-item">
                <img src="@/assets/images/icon1.svg" alt="" />
                <p class="icon-text">Cambridge Checkpoints</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon2.png" alt="" />
                <p class="icon-text">Essential Learner Enrichment</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon3.png" alt="" />
                <p class="icon-text">Extended Learner Development</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon4.png" alt="" />
                <p class="icon-text">Elixir Buzz</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon5.png" alt="" />
                <p class="icon-text">Student Governance System</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon6.png" alt="" />
                <p class="icon-text">Curriculum & Pedagogy</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon7.png" alt="" />
                <p class="icon-text">Collaborations & Partnerships</p>
              </div>
            </div>
            <div class="marquee-content scroll reverse-scroll">
              <div class="marquee-item">
                <img src="@/assets/images/icon1.svg" alt="" />
                <p class="icon-text">Cambridge Checkpoints</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon2.png" alt="" />
                <p class="icon-text">Essential Learner Enrichment</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon3.png" alt="" />
                <p class="icon-text">Extended Learner Development</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon4.png" alt="" />
                <p class="icon-text">Elixir Buzz</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon5.png" alt="" />
                <p class="icon-text">Student Governance System</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon6.png" alt="" />
                <p class="icon-text">Curriculum & Pedagogy</p>
              </div>
              <div class="marquee-item">
                <img src="@/assets/images/icon7.png" alt="" />
                <p class="icon-text">Collaborations & Partnerships</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="style-103676 page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img
                                src="@/assets/images/facilityHeading.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="my-content">
                        Our campus is located in the heart of Karachi, centrally
                        positioned in peaceful vicinity of North Nazimabad. It
                        spreads across 40,000 sq. yards and is Solar Powered
                        emphasizing our focus on sustainability. Its facilities
                        include:
                        <br /><br />
                        <ul>
                          <li>Early Childhood Learning Centre (ECLC)</li>
                          <li>Fully Equipped Science and Computer Labs</li>
                          <li>Spacious, airy and well lit rooms</li>
                          <li>Indoor and outdoor art facilities</li>
                          <li>Young kids' play area</li>
                          <li>Students’ Lockers</li>
                          <li>Outdoor sport facilities and grounds</li>
                          <li>
                            Birds aviary housing more than 25 species of birds
                            and parrots
                          </li>
                          <li>Animal habitat (Zootopia)</li>
                          <li>Lush green gardens for exploration</li>
                          <li>Organic kitchen garden</li>
                          <li>Outdoor cafeteria</li>
                          <li>Solar powered facility</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="page-row row">
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper single-image-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div class="">
                        <img src="@/assets/images/indi3.jpg" alt="" />
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col single-content-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <h2 class="my-heading">Foreign Languages</h2>
                            <div class="my-content">
                              World famous Languages ike Mandarin, German and
                              French, are offered from Grade 4 onwards by
                              linguists and highly experienced faculty. Under
                              this program, the students not only leam a new
                              language but are also exposed to different
                              cultures and schools of thought. They are also
                              prepared for Professional Certificate Exam in
                              their chosen language. <br /><br />
                              Through close collaboration with the Goethe
                              Institute and Alliance Francaise. the students are
                              exposed to international and inter school level
                              activities hosted by these organizations which not
                              only strengthen the language skills but also build
                              confidence in the students.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="page-row row">
                    <div
                      class="first-col style-103700 page-col span12 col single-content-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <h2 class="my-heading">Science Ird</h2>
                            <div class="my-content">
                              Strong emphasis is placed on developing Scientific
                              and Mathematical concepts in students with a focus
                              on solving ‘society's problems. Specifically the
                              STEM program integrates subjects of Science,
                              Technology, Engineering & Mathematics (STEM) into
                              a cohesive learning paradigm based on real world
                              applications. Practical application of concepts is
                              encouraged and students are constantly engaged in
                              demonstrating these. through real life working
                              models.
                              <br /><br />
                              Emphasis on environment and sustainability
                              encourages the. students 10 research and implement
                              methods to grow fruits, vegetables and other
                              plants at a dedicated organic garden in the
                              school. Students not only research the
                              environmental determinants of growing plants and
                              sustainable farming but also leam the commercial
                              aspects which are in line with the themes of
                              Entrepreneurship. This exercise brings together an
                              integrated education program encompassing
                              Geography, Science, Mathematics and Business
                              studies.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper single-image-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div class="">
                        <img src="@/assets/images/DSC00358.jpg" alt="" />
                        <!-- <img src="@/assets/images/facility2.png" alt="" /> -->
                      </div>
                    </div>
                  </div>

                  <div class="page-row row">
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper single-image-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div class="">
                        <img src="@/assets/images/indi4.jpg" alt="" />
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col single-content-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <h2 class="my-heading">Artist in Residence</h2>
                            <div class="my-content">
                              Research studies indicate that art is extremely
                              important in not only developing | critical
                              thinking, creativity, visual analysis,
                              communication and literacy skill in children but
                              also provide therapeutic outlets for releasing
                              stress and increased motivation and achievement
                              levels. It is with this viewpoint that
                              Artist-In-Residency program was set up at The Elir
                              School
                              <br /><br />
                              The Artistn-Residency program is first of ts kind
                              to be implemented by any school in Karachi. This s
                              to encourage creative leaning and awareness of the
                              art among students. Each resident artist
                              establishes his/her own studio at the school in
                              eight week rotation, where he/she not only work on
                              individual art pieces but also conduct workshops
                              on Art, History of Arts & Philosophical Approaches
                              for the students and teachers. The student's work
                              along with the art work produced by the artist
                              during the residency is later exhibited. Our
                              students benefit from the expertise of established
                              artists by end of each school year and learn a
                              myriad of art and creative techniques via inter
                              disciplinary activities.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="page-row row">
                    <div
                      class="first-col style-103700 page-col span12 col single-content-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content">
                          <div class="content-wrap">
                            <h2 class="my-heading">Curriculum</h2>
                            <div class="my-content">
                              The Elixir School follows Cambridge Curriculum
                              right from Grade | up 10 0 Levels, whereas the
                              Nursery and KG program is based on an integrated
                              curriculum between International Montessori
                              Programs and latest developments and pedagogies in
                              Early Childhood Education.
                              <br /><br />
                              <b>Key Co-Curricular Programs:</b>
                              <br /><br />
                              <ul>
                                <li>
                                  Tehzeeb - Basic manners, etiquettes and life
                                  skill program.
                                </li>
                                <li>
                                  Foreign Language Programs (Grade 4 onwards -
                                  French/German/Mandarin)
                                </li>
                                <li>Taekwondo (Grade onwards)</li>
                                <li>Yoga (Nursery onwards)</li>
                                <li>Gymnastics (Nursery onwards)</li>
                                <li>
                                  Intemational Student Exchange Progams (Grade 9
                                  onwards)
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper single-image-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div class="">
                        <img src="@/assets/images/facility4.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="style-105032 region layout-region" id="layout_region_5">
        <!-- style="background-color: #052348" -->
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177978"
              >
                <div
                  class="layout_block_177978 region page-region"
                  id="page_region_175236"
                >
                  <div class="style-103676 page-row row">
                    <div class="first-col span4 page-col col">
                      <div
                        class="page-block clearfix"
                        data-cid="1"
                        id="content_3128316"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/text/acres.jpg" alt="" /> -->
                              <img
                                src="@/assets/images/icons8-country-100.png"
                                alt=""
                              />
                              <p class="icon-text">7 acres</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span4 col">
                      <div
                        class="page-block clearfix"
                        data-cid="1"
                        id="content_3128317"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/text/indoor.jpg" alt="" /> -->
                              <img
                                src="@/assets/images/icons8-ai-100.png"
                                alt=""
                              />
                              <p class="icon-text">AI Integrated</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span4 col">
                      <div
                        class="page-block clearfix"
                        data-cid="1"
                        id="content_3128318"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/text/organic.jpg" alt="" /> -->
                              <img
                                src="@/assets/images/icons8-cctv-100.png"
                                alt=""
                              />
                              <p class="icon-text">24/7 CCTV</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span4 col">
                      <div
                        class="page-block clearfix"
                        data-cid="1"
                        id="content_3128319"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/text/learning.jpg" alt="" /> -->
                              <img
                                src="@/assets/images/icons8-energy-saving-bulb-100.png"
                                alt=""
                              />
                              <p class="icon-text">Green Energy</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span4 col">
                      <div
                        class="page-block clearfix"
                        data-cid="1"
                        id="content_3128320"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/text/artist.jpg" alt="" /> -->
                              <img
                                src="@/assets/images/icons8-bus-100.png"
                                alt=""
                              />
                              <p class="icon-text">Drive through pick & drop</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span4 col">
                      <div
                        class="page-block clearfix"
                        data-cid="1"
                        id="content_3128321"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/text/foreign.jpg" alt="" /> -->

                              <img
                                src="@/assets/images/icons8-faneuil-hall-100.png"
                                alt=""
                              />
                              <p class="icon-text">Purpose built facility</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span4 col">
                      <div
                        class="page-block clearfix"
                        data-cid="1"
                        id="content_3128321"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img
                                src="@/assets/images/icons8-education-100.png"
                                alt=""
                              />
                              <p class="icon-text">Holistic education</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span4 col">
                      <div
                        class="page-block clearfix"
                        data-cid="1"
                        id="content_3128321"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img
                                src="@/assets/images/icons8-leaderboard-100.png"
                                alt=""
                              />
                              <p class="icon-text">
                                Indoor & Outdoor Sports arena
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span4 col">
                      <div
                        class="page-block clearfix"
                        data-cid="1"
                        id="content_3128321"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img
                                src="@/assets/images/icons8-garden-100.png"
                                alt=""
                              />
                              <p class="icon-text">Organic Garden</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row" style="margin: 0">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img src="@/assets/images/tehzeeb.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper single-image-wrapper"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <div class="">
                        <img src="@/assets/images/indi5.jpg" alt="" />
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col single-content-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <div class="my-content">
                              This program aims to foster the development of
                              responsible individuals by inculcating the moral
                              and ethical values in students. It teaches them
                              values of kindness, honesty, responsibilty
                              respect, humility and other important traits
                              necessary to be there in a good human being
                              <br /><br />
                              Since students spend most of their quality time at
                              school, it is the perfect place to instil moral
                              values in them. Teaching of character building
                              helps to prepare students face opportunites as
                              well as challenges existing in today's
                              contemporary world
                              <br /><br />
                              Tehzeeb also focuses on life skills needed for an
                              individual to lead a healthy, responsible and
                              fulfilling life. These include Islamic moral and
                              ethical values, civic duties, etc.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img src="@/assets/images/physical.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="my-content">
                        Comprehensive Physical Education programs having focus
                        on development of mind, body and soul are conducted by
                        specialized and highly experienced trainers.
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col page-col span12 col"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div class="">
                        <div class="my-content">
                          <h2 class="my-heading">Gymnastics</h2>
                          Gymnastics is widely renowned as sports that can
                          improve overall fitness, strength, balance and body
                          control. Extended participation in gymnastic classes
                          provides many additional cognitive and psychological
                          benefits which indude social interaction and
                          discipline, improved mental focus and concentration
                          and understanding of physical flexibility, balance and
                          co-ordination.
                        </div>
                      </div>
                    </div>
                    <div
                      class="first-col page-col span12 col"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div class="set-margin">
                        <div class="my-content">
                          <h2 class="my-heading">Taekwondo</h2>
                          Taekwondo is both an ancient Korean martial art and a
                          modern Intemational Olympic Sport. It is a mental and
                          physical discipline designed over 2000 years with the
                          ultimate goal of improving mental and physical health.
                          It enhances self-esteem, builds confidence, develops
                          discipline, teaches self-defense with self-control and
                          strengthens the students’ mind and body. some very key
                          features to live a happy healthy life.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col page-col span12 col"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div class="">
                        <div class="my-content">
                          <h2 class="my-heading">Yoga</h2>
                          Yoga classes are conducted to help the children
                          develop focus and concentration. It also helps the
                          students manage stress through breathing, awareness,
                          meditation and healthy movement. Increases confidence
                          and positive self image helping them feel part of a
                          heathy yet non competitive group.
                        </div>
                      </div>
                    </div>
                    <div
                      class="first-col page-col span12 col"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div class="set-margin">
                        <div class="my-content">
                          <h2 class="my-heading">Football</h2>
                          Football promotes collaboration and team spirit. It
                          fosters camaraderie between students, maintains
                          discipline, introduces work ethics and provides mental
                          and physical strength.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col page-col span12 col"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div class="">
                        <div class="my-content">
                          <h2 class="my-heading">Other Sports</h2>
                          Apart, students participate in sports activities
                          including Cricket, Futsal, Basketball, Archery, Ten
                          Pin Bowling and Table Tennis.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="style-105032 region layout-region" id="layout_region_4">
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177517"
              >
                <!-- <div class="layout_block_177611 region page-region" id="my_page_region">
                  <h1 class="h1-style page-title page-title-level-1">Our Facilities</h1>
                  <div class="page-row row">
                    <div class="first-col page-col span11 col">
                      <div class="page-block style-103672 clearfix" data-cid="2" id="content_3122164">
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a href="#" class="custom-link-photo style-no-select links-imglink">
                                  <img src="@/assets/images/facility1.jpg" class="link-image" alt="" />
                                </a>
                                <div class="facility-overlay"></div>
                                <div class="facility-content">
                                  <h2>Foreign Languages</h2>
                                  <p>
                                    Languages, Mandarin, German and French, are offered from Grade 4 onwards by expert
                                    and highly experienced faculty. Under this program, the students not only learn a
                                    new language but are also exposed to different cultures and schools of thought. They
                                    are also prepared for Professional Certificate Exam in their chosen language.
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span11 col">
                      <div class="page-block style-103672 clearfix" data-cid="2" id="content_3122165">
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a href="#" class="custom-link-photo style-no-select links-imglink">
                                  <img src="@/assets/images/facility2.png" class="link-image" alt="" />
                                </a>
                                <div class="facility-overlay"></div>
                                <div class="facility-content">
                                  <h2>SCIENCE IRD</h2>
                                  <p>
                                    Strong emphasis is placed on developing Scientific and Mathematical concepts in
                                    students with a focus on solving society’s problems. Specifically, the STEM program
                                    integrates subjects of Science, Technology, Engineering & Mathematics (STEM) into a
                                    cohesive learning paradigm based on real-world applications.
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span11 col">
                      <div class="page-block style-103672 clearfix" data-cid="2" id="content_3122166">
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a href="#" class="custom-link-photo style-no-select links-imglink">
                                  <img src="@/assets/images/facility3.png" class="link-image" alt="" />
                                </a>
                                <div class="facility-overlay"></div>
                                <div class="facility-content">
                                  <h2>ARTIST IN RESIDENCE</h2>
                                  <p>
                                    Languages, Mandarin, German and French, are offered from Grade 4 onwards by expert
                                    and highly experienced faculty. Under this program, the students not only learn a
                                    new language but are also exposed to different cultures and schools of thought. They
                                    are also prepared for Professional Certificate Exam in their chosen language.
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="last-col page-col span11 col">
                      <div class="page-block style-103672 clearfix" data-cid="2" id="content_3122167">
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a href="#" class="custom-link-photo style-no-select links-imglink">
                                  <img src="@/assets/images/facility4.png" class="link-image" alt="" />
                                </a>
                                <div class="facility-overlay"></div>
                                <div class="facility-content">
                                  <h2>CURRICULUM</h2>
                                  <p>
                                    The Elixir School follows Cambridge Curriculum right from Grade I up to O Levels,
                                    whereas the Nursery and KG programs are based on an integration between
                                    International Montessori Programs and latest developments and pedagogies in Early
                                    Childhood Education (ECE).
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

                <div
                  class="layout_block_177517 region page-region"
                  id="page_region_174606"
                >
                  <div class="style-103676 page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3119619"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img src="@/assets/images/highlight.png" alt="" />

                              <!-- <img
                                src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/text/large_text1988765_89010.png"
                                alt=""
                              /> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col page-col span4 col"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <div
                        class="page-block style-105242 clearfix"
                        data-cid="2"
                        id="content_3118721"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <img
                                  src="@/assets/images/DSC00338.jpg"
                                  class="link-image"
                                  alt=""
                                />
                                <div class="highlight-overlay"></div>
                                <div class="highlight-content">
                                  <h3>Curriculum</h3>
                                </div>
                                <!-- <img
                                  src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4642996_151772.jpg"
                                  class="link-image"
                                  alt="AC Plus"
                                /> -->
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-col span4 col"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <div
                        class="page-block style-105242 clearfix"
                        data-cid="2"
                        id="content_3118722"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <img
                                  src="@/assets/images/DSC00358.jpg"
                                  class="link-image"
                                  alt=""
                                />
                                <div class="highlight-overlay"></div>
                                <div class="highlight-content">
                                  <h3>Artist in Residence</h3>
                                </div>
                                <!-- <img
                                  src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4643001_151773.jpg"
                                  class="link-image"
                                  alt="AP Capstone"
                                /> -->
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="last-col page-col span4 col"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <div
                        class="page-block style-105242 clearfix"
                        data-cid="2"
                        id="content_3119563"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <img
                                  src="@/assets/images/DSC00368.jpg"
                                  class="link-image"
                                  alt=""
                                />
                                <div class="highlight-overlay"></div>
                                <div class="highlight-content">
                                  <h3>Music & Band</h3>
                                </div>
                                <!-- <img
                                  src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4643023_151774.jpg"
                                  class="link-image"
                                  alt="Robotics"
                                /> -->
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="first-col page-col span4 col"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <div
                        class="page-block style-105242 clearfix"
                        data-cid="2"
                        id="content_3119561"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <img
                                  src="@/assets/images/DSC00359.jpg"
                                  class="link-image"
                                  alt=""
                                />
                                <div class="highlight-overlay"></div>
                                <div class="highlight-content">
                                  <h3>Robotics</h3>
                                </div>
                                <!-- <img
                                  src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4643016_151775.jpg"
                                  class="link-image"
                                  alt="McEwen"
                                /> -->
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-col span4 col"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <div
                        class="page-block style-105242 clearfix"
                        data-cid="2"
                        id="content_3119562"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <img
                                  src="@/assets/images/DSC00337.jpg"
                                  class="link-image"
                                  alt=""
                                />
                                <div class="highlight-overlay"></div>
                                <div class="highlight-content">
                                  <h3>Tehzeeb</h3>
                                </div>
                                <!-- <img
                                  src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4643020_151776.jpg"
                                  class="link-image"
                                  alt="SAC - Home - Outdoor Ed"
                                /> -->
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="last-col page-col span4 col"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <div
                        class="page-block style-105242 clearfix"
                        data-cid="2"
                        id="content_3118723"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <img
                                  src="@/assets/images/DSC00548.jpg"
                                  class="link-image"
                                  alt=""
                                />
                                <div class="highlight-overlay"></div>
                                <div class="highlight-content">
                                  <h3>Zoothopia</h3>
                                </div>
                                <!-- <img
                                  src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4643002_151777.jpg"
                                  class="link-image"
                                  alt="Health &amp; Wellness"
                                /> -->
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="last-col page-col span4 col"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <div
                        class="page-block style-105242 clearfix"
                        data-cid="2"
                        id="content_3118723"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <img
                                  src="@/assets/images/indi1.jpg"
                                  class="link-image"
                                  alt=""
                                />
                                <div class="highlight-overlay"></div>
                                <div class="highlight-content">
                                  <h3>Health & Wellness</h3>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="last-col page-col span4 col"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <div
                        class="page-block style-105242 clearfix"
                        data-cid="2"
                        id="content_3118723"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <img
                                  src="@/assets/images/indi2.jpg"
                                  class="link-image"
                                  alt=""
                                />
                                <div class="highlight-overlay"></div>
                                <div class="highlight-content">
                                  <h3>Elixir Leadership</h3>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="last-col page-col span4 col"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <div
                        class="page-block style-105242 clearfix"
                        data-cid="2"
                        id="content_3118723"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <img
                                  src="@/assets/images/indi3.jpg"
                                  class="link-image"
                                  alt=""
                                />
                                <div class="highlight-overlay"></div>
                                <div class="highlight-content">
                                  <h3>Foreign Languages</h3>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="style-103644 region layout-region" id="layout_region_6">
        <div class="full-width">
          <div class="layout-row row-fluid">
            <div class="last-col first-col layout-col span24 col">
              <div class="layout-block clearfix" data-cid="390" id="content_177614">
                <div class="layout_block_177614 region page-region" id="page_region_174828">
                  <div class="style-103676 page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div class="page-block style-105241 clearfix" data-cid="1" id="content_3120675">
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <img
                                src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/text/large_text1985668_88974.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="style-105032 page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div class="page-block style-103720 clearfix" data-cid="6" id="content_3225342">
                        <div class="content news">
                          <div class="content-wrap carousel-wrap">
                            <div
                              id="carousel-3225342"
                              class="carousel crl-container flexslider carousel-3225342 smoothheight no-auto-advance pager pager-position-bottom pager-location-outside pager-alignment-center show-counter counter-position-top counter-alignment-left show-caption caption-position-bottom caption-location-inside animation-slide"
                              type="6"
                              startat="1"
                              mode="2"
                              slideshowspeed="5"
                              animationspeed="1"
                              minitem="1"
                              maxitem="3"
                              slidewidth="300"
                              thumbmargin="0"
                              thumbheight="100"
                              thumbwidth="100"
                            >
                              <ul class="slides">
                                <li
                                  data-thumb="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1495980_1349472.jpg"
                                >
                                  <figure
                                    data-title="Celtic Concert takes the stage for two nights in Wirth Theatre"
                                    data-caption=""
                                    data-long=""
                                    data-date=""
                                    data-author=""
                                    data-detailurl="/page/news-detail?pk=1495980&fromId=284225"
                                  >
                                    <div
                                      class="carousel-photo"
                                      data-caption=""
                                      data-title="Celtic Concert takes the stage for two nights in Wirth Theatre"
                                    >
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/thumb_news1495980_1349472.jpg"
                                        data-width="240"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1495980_1349472.jpg"
                                        data-width="960"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/zoom_news1495980_1349472.jpg"
                                        data-width="1200"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/orig_news1495980_1349472.jpg"
                                        data-width="960"
                                      ></span>
                                      <img
                                        src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1495980_1349472.jpg"
                                        class="carousel-image"
                                        alt="Celtic Concert"
                                      />
                                    </div>
                                    <figcaption class="details crl-details">
                                      <div class="caption-spacing crl-inner-details">
                                        <h3 class="h4-style">
                                          <a class="crl-title" href="news-detail123c.html?pk=1495980&amp;fromId=284225"
                                            >Celtic Concert takes the stage for two nights in Wirth Theatre</a
                                          >
                                        </h3>
                                      </div>
                                    </figcaption>
                                  </figure>
                                </li>
                                <li
                                  data-thumb="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1495093_1348935.jpg"
                                >
                                  <figure
                                    data-title="SAC VEX Robotics team qualifies for World Championships"
                                    data-caption=""
                                    data-long=""
                                    data-date=""
                                    data-author=""
                                    data-detailurl="/page/news-detail?pk=1495093&fromId=284225"
                                  >
                                    <div
                                      class="carousel-photo"
                                      data-caption=""
                                      data-title="SAC VEX Robotics team qualifies for World Championships"
                                    >
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/thumb_news1495093_1348935.jpeg"
                                        data-width="240"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1495093_1348935.jpeg"
                                        data-width="960"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/zoom_news1495093_1348935.jpeg"
                                        data-width="1200"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/orig_news1495093_1348935.jpeg"
                                        data-width="960"
                                      ></span>
                                      <img
                                        src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1495093_1348935.jpg"
                                        class="carousel-image"
                                        alt=""
                                      />
                                    </div>
                                    <figcaption class="details crl-details">
                                      <div class="caption-spacing crl-inner-details">
                                        <h3 class="h4-style">
                                          <a class="crl-title" href="news-detail0d24.html?pk=1495093&amp;fromId=284225"
                                            >SAC VEX Robotics team qualifies for World Championships</a
                                          >
                                        </h3>
                                      </div>
                                    </figcaption>
                                  </figure>
                                </li>
                                <li
                                  data-thumb="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1492016_1347034.jpg"
                                >
                                  <figure
                                    data-title="Two more VEX Robotics teams qualify for Provincials"
                                    data-caption=""
                                    data-long=""
                                    data-date=""
                                    data-author=""
                                    data-detailurl="/page/news-detail?pk=1492016&fromId=284225"
                                  >
                                    <div
                                      class="carousel-photo"
                                      data-caption=""
                                      data-title="Two more VEX Robotics teams qualify for Provincials"
                                    >
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/thumb_news1492016_1347034.jpg"
                                        data-width="240"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1492016_1347034.jpg"
                                        data-width="960"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/zoom_news1492016_1347034.jpg"
                                        data-width="1200"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/orig_news1492016_1347034.jpg"
                                        data-width="960"
                                      ></span>
                                      <img
                                        src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1492016_1347034.jpg"
                                        class="carousel-image"
                                        alt="SAC Vex Robitics"
                                      />
                                    </div>
                                    <figcaption class="details crl-details">
                                      <div class="caption-spacing crl-inner-details">
                                        <h3 class="h4-style">
                                          <a class="crl-title" href="news-detail6d0e.html?pk=1492016&amp;fromId=284225"
                                            >Two more VEX Robotics teams qualify for Provincials</a
                                          >
                                        </h3>
                                      </div>
                                    </figcaption>
                                  </figure>
                                </li>
                                <li
                                  data-thumb="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1488555_1344882.jpg"
                                >
                                  <figure
                                    data-title="SAC Vex Robotics teams significantly move up the world rankings"
                                    data-caption=""
                                    data-long=""
                                    data-date=""
                                    data-author=""
                                    data-detailurl="/page/news-detail?pk=1488555&fromId=284225"
                                  >
                                    <div
                                      class="carousel-photo"
                                      data-caption=""
                                      data-title="SAC Vex Robotics teams significantly move up the world rankings"
                                    >
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/thumb_news1488555_1344882.jpg"
                                        data-width="240"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1488555_1344882.jpg"
                                        data-width="960"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/zoom_news1488555_1344882.jpg"
                                        data-width="1200"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/orig_news1488555_1344882.jpg"
                                        data-width="960"
                                      ></span>
                                      <img
                                        src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1488555_1344882.jpg"
                                        class="carousel-image"
                                        alt="Vex Robotics team competes at event"
                                      />
                                    </div>
                                    <figcaption class="details crl-details">
                                      <div class="caption-spacing crl-inner-details">
                                        <h3 class="h4-style">
                                          <a class="crl-title" href="news-detail65c2.html?pk=1488555&amp;fromId=284225"
                                            >SAC Vex Robotics teams significantly move up the world rankings</a
                                          >
                                        </h3>
                                      </div>
                                    </figcaption>
                                  </figure>
                                </li>
                                <li
                                  data-thumb="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1483599_1341847.jpg"
                                >
                                  <figure
                                    data-title="Business students pitch their ideas at the McEwen Entrepreneurship Fair"
                                    data-caption=""
                                    data-long=""
                                    data-date=""
                                    data-author=""
                                    data-detailurl="/page/news-detail?pk=1483599&fromId=284225"
                                  >
                                    <div
                                      class="carousel-photo"
                                      data-caption=""
                                      data-title="Business students pitch their ideas at the McEwen Entrepreneurship Fair"
                                    >
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/thumb_news1483599_1341847.jpg"
                                        data-width="240"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1483599_1341847.jpg"
                                        data-width="960"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/zoom_news1483599_1341847.jpg"
                                        data-width="1200"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/orig_news1483599_1341847.jpg"
                                        data-width="960"
                                      ></span>
                                      <img
                                        src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1483599_1341847.jpg"
                                        class="carousel-image"
                                        alt=""
                                      />
                                    </div>
                                    <figcaption class="details crl-details">
                                      <div class="caption-spacing crl-inner-details">
                                        <h3 class="h4-style">
                                          <a class="crl-title" href="news-detail33d5.html?pk=1483599&amp;fromId=284225"
                                            >Business students pitch their ideas at the McEwen Entrepreneurship Fair</a
                                          >
                                        </h3>
                                      </div>
                                    </figcaption>
                                  </figure>
                                </li>
                                <li
                                  data-thumb="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1481000_1340243.jpg"
                                >
                                  <figure
                                    data-title="An Andrean Christmas brings seasonal joy"
                                    data-caption=""
                                    data-long=""
                                    data-date=""
                                    data-author=""
                                    data-detailurl="/page/news-detail?pk=1481000&fromId=284225"
                                  >
                                    <div
                                      class="carousel-photo"
                                      data-caption=""
                                      data-title="An Andrean Christmas brings seasonal joy"
                                    >
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/thumb_news1481000_1340243.jpg"
                                        data-width="240"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1481000_1340243.jpg"
                                        data-width="960"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/zoom_news1481000_1340243.jpg"
                                        data-width="1200"
                                      ></span>
                                      <span
                                        data-src="//bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/orig_news1481000_1340243.jpg"
                                        data-width="960"
                                      ></span>
                                      <img
                                        src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/news/large_news1481000_1340243.jpg"
                                        class="carousel-image"
                                        alt="On stage at Roy Thomson Hall."
                                      />
                                    </div>
                                    <figcaption class="details crl-details">
                                      <div class="caption-spacing crl-inner-details">
                                        <h3 class="h4-style">
                                          <a class="crl-title" href="news-detail7013.html?pk=1481000&amp;fromId=284225"
                                            >An Andrean Christmas brings seasonal joy</a
                                          >
                                        </h3>
                                      </div>
                                    </figcaption>
                                  </figure>
                                </li>
                              </ul>
                              <div class="counter crl-counter">
                                <span class="current-image crl-image-count">1</span
                                ><span class="separator crl-counter-separator">/</span
                                ><span class="crl-image-total">6</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="style-103644 region layout-region" id="layout_region_7">
        <div class="full-width">
          <div class="style-103676 layout-row row-fluid">
            <div class="last-col first-col layout-col span24 col">
              <div class="layout-block clearfix" data-cid="390" id="content_178178">
                <div class="layout_block_178178 region page-region" id="page_region_175470">
                  <div class="style-103676 page-row row">
                    <div class="first-col page-col span6 col">
                      <div class="page-block style-103801 clearfix" data-cid="2" id="content_3134889">
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a
                                  href="admission/request-information.html"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4691411_151408.png"
                                    class="link-image"
                                    alt="Inquire"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span6 col">
                      <div class="page-block style-103801 clearfix" data-cid="2" id="content_3134890">
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a
                                  href="admission/admission-team.html"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4691418_151409.png"
                                    class="link-image"
                                    alt="Visit"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="page-col span6 col">
                      <div class="page-block style-103801 clearfix" data-cid="2" id="content_3134891">
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a
                                  href="https://sac.schooladmin.ca/"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4691423_152249.png"
                                    class="link-image"
                                    alt="Apply"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="last-col page-col span6 col">
                      <div class="page-block style-103801 clearfix" data-cid="2" id="content_3206062">
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a
                                  href="https://www.facebook.com/standrewscollege"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/link/large_link4823035_152248.png"
                                    class="link-image"
                                    alt="Follow Us"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import FsLightbox from "fslightbox-vue";

export default {
  name: "HomeView",
  components: {
    FsLightbox,
  },
  data() {
    return {
      toggler: false,
      storiesData: [
        {
          title: "Mother's Day",
          thumbnail: require("@/assets/images/gallery/m14.jpeg"),
          images: [
            require("@/assets/images/gallery/m14.jpeg"),
            require("@/assets/images/gallery/m1.jpg"),
            require("@/assets/images/gallery/m4.jpg"),
            require("@/assets/images/gallery/m5.jpg"),
            require("@/assets/images/gallery/m6.jpg"),
            require("@/assets/images/gallery/m3.jpg"),
            require("@/assets/images/gallery/m24.jpeg"),
            require("@/assets/images/gallery/m34.jpeg"),
            require("@/assets/images/gallery/m23.jpeg"),
            require("@/assets/images/gallery/m31.jpeg"),
            require("@/assets/images/gallery/m32.jpeg"),
            require("@/assets/images/gallery/m29.jpeg"),
            require("@/assets/images/gallery/m30.jpeg"),
            require("@/assets/images/gallery/m2.jpg"),
            require("@/assets/images/gallery/m8.jpg"),
            require("@/assets/images/gallery/m9.jpg"),
            require("@/assets/images/gallery/m10.jpg"),
            require("@/assets/images/gallery/m11.jpg"),
            require("@/assets/images/gallery/m12.jpg"),
            require("@/assets/images/gallery/m7.jpg"),
            require("@/assets/images/gallery/m16.jpeg"),
            require("@/assets/images/gallery/m17.jpeg"),
          ],
        },
        {
          title: "STEM Compettion",
          thumbnail: require("@/assets/images/gallery/stem1.jpg"),
          images: [
            require("@/assets/images/gallery/stem1.jpg"),
            require("@/assets/images/gallery/stem2.jpg"),
            require("@/assets/images/gallery/stem3.jpg"),
            require("@/assets/images/gallery/stem4.jpg"),
            require("@/assets/images/gallery/stem5.jpg"),
            require("@/assets/images/gallery/stem6.jpg"),
            require("@/assets/images/gallery/stem7.jpg"),
            require("@/assets/images/gallery/stem8.jpg"),
            require("@/assets/images/gallery/stem9.jpg"),
            require("@/assets/images/gallery/stem10.jpg"),
            require("@/assets/images/gallery/stem11.jpg"),
            require("@/assets/images/gallery/stem12.jpg"),
            require("@/assets/images/gallery/stem13.jpg"),
            require("@/assets/images/gallery/stem14.jpg"),
            require("@/assets/images/gallery/stem15.jpg"),
            require("@/assets/images/gallery/stem16.jpg"),
          ],
        },
        {
          title: "Earth Day",
          thumbnail: require("@/assets/images/gallery/1.jpeg"),
          images: [
            require("@/assets/images/gallery/1.jpeg"),
            require("@/assets/images/gallery/2.jpeg"),
            require("@/assets/images/gallery/3.jpeg"),
            require("@/assets/images/gallery/4.jpeg"),
            require("@/assets/images/gallery/5.jpeg"),
            require("@/assets/images/gallery/6.jpeg"),
            require("@/assets/images/gallery/7.jpeg"),
            require("@/assets/images/gallery/8.jpeg"),
            require("@/assets/images/gallery/9.jpeg"),
            require("@/assets/images/gallery/10.jpeg"),
            require("@/assets/images/gallery/21.jpeg"),
            require("@/assets/images/gallery/11.jpeg"),
            require("@/assets/images/gallery/22.jpeg"),
            require("@/assets/images/gallery/23.jpeg"),
            require("@/assets/images/gallery/24.jpeg"),
            require("@/assets/images/gallery/25.jpeg"),
            require("@/assets/images/gallery/26.jpeg"),
            require("@/assets/images/gallery/27.jpeg"),
          ],
        },
        {
          title: "Art & Craft",
          thumbnail: require("@/assets/images/gallery/12.jpeg"),
          images: [
            require("@/assets/images/gallery/12.jpeg"),
            require("@/assets/images/gallery/13.jpeg"),
            require("@/assets/images/gallery/14.jpeg"),
            require("@/assets/images/gallery/15.jpeg"),
            require("@/assets/images/gallery/16.jpeg"),
            require("@/assets/images/gallery/17.jpeg"),
            require("@/assets/images/gallery/18.jpeg"),
            require("@/assets/images/gallery/19.jpeg"),
            require("@/assets/images/gallery/20.jpeg"),
            require("@/assets/images/gallery/28.jpeg"),
          ],
        },
        {
          title: "Gardening",
          thumbnail: require("@/assets/images/gallery/29.jpeg"),
          images: [
            require("@/assets/images/gallery/29.jpeg"),
            require("@/assets/images/gallery/30.jpeg"),
            require("@/assets/images/gallery/31.jpeg"),
            require("@/assets/images/gallery/32.jpeg"),
            require("@/assets/images/gallery/33.jpeg"),
            require("@/assets/images/gallery/34.jpeg"),
            require("@/assets/images/gallery/35.jpeg"),
            require("@/assets/images/gallery/36.jpeg"),
            require("@/assets/images/gallery/37.jpeg"),
          ],
        },
        {
          title: "Class Activities",
          thumbnail: require("@/assets/images/indi1.jpg"),
          images: [
            require("@/assets/images/indi1.jpg"),
            require("@/assets/images/indi2.jpg"),
            require("@/assets/images/indi3.jpg"),
            require("@/assets/images/indi4.jpg"),
            require("@/assets/images/indi5.jpg"),
            require("@/assets/images/indi6.jpg"),
            require("@/assets/images/indi7.jpg"),
            require("@/assets/images/indi8.jpg"),
          ],
        },
        // {
        //   thumbnail: require("@/assets/images/gallery/12.jpeg"),
        //   images: [
        //     require("@/assets/images/gallery/vid1.mp4"),
        //     require("@/assets/images/gallery/vid2.mp4"),
        //   ],
        // },
      ],
      currentImages: [],
      slideIndex: 1,
      intervalID: null,
      nextBtn: null,
    };
  },
  methods: {
    openStory(images, ind) {
      this.slideIndex = 1;
      this.currentImages = images;
      this.toggler = !this.toggler;
    },
    setSlide() {
      let obj = document.querySelectorAll(
        ".fslightbox-absoluted.fslightbox-full-dimension.fslightbox-flex-centered"
      );
      // console.log(obj);
      if (obj.length == 1) {
        obj[0].style.transform = "unset";
      }
      if (obj.length > 1) {
        this.nextBtn = document.querySelector(
          ".fslightbox-slide-btn-next-container"
        );
        this.intervalID = setInterval(this.nextSlide, 3000);
        // console.log(this.nextBtn, this.intervalID);
      }
    },
    nextSlide() {
      this.nextBtn.click();
    },
    closeSlide() {
      if (this.intervalID) clearInterval(this.intervalID);
      this.intervalID = null;
      this.nextBtn = null;
    },
  },
};
</script>
