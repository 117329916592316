<template>
  <div>
    <LvDialog
      header="Elixir App"
      v-model="displayBasic"
      :style="{ maxWidth: '900px', width: '100%' }"
    >
      <div class="app-links">
        <div>
          <video playsinline="" autoplay controls class="app-video">
            <source
              type="video/mp4"
              src="@/assets/video/elixir-app-tutorial.mp4"
            />
          </video>
        </div>
        <a
          href="https://play.google.com/store/apps/details?id=com.myskool.elixir"
          target="_blank"
        >
          <img src="@/assets/images/playstore.svg" width="135" alt="" />
        </a>
        <a
          href="https://apps.apple.com/pk/app/elixir-school/id6503348432"
          target="_blank"
        >
          <img src="@/assets/images/apple.svg" width="135" alt="" />
        </a>
      </div>
    </LvDialog>
    <div
      class="visible-phone page-block style-97855 clearfix"
      data-cid="414"
      id="content_414"
    >
      <div class="content mobilemenu">
        <div role="navigation" id="dl-menu" class="dl-menuwrapper mm">
          <ul class="mm-button-container">
            <li class="mm-buttons">
              <a
                class="dl-trigger mm-button"
                :class="{ 'hide-icon': menuOpen }"
                role="button"
                aria-label="Menu"
                tabindex="0"
                @click="menuOpen = true"
                ><span class="icon"></span><span class="label"></span
              ></a>
              <a
                class="mm-close"
                tabindex="0"
                :class="{ 'show-icon': menuOpen }"
                @click="menuOpen = false"
                >&times;</a
              >
            </li>
            <li>
              <div
                class="mm-title"
                :style="{
                  'background-image': `url(${smallMedia(
                    logoData.data.attributes
                  )})`,
                }"
              >
                <span></span>
              </div>
            </li>
            <li class="mm-buttons mm-login"></li>
          </ul>

          <ul
            v-if="menuData.length > 0"
            class="dl-menu mm-container"
            :class="{ 'dl-menuopen': menuOpen }"
          >
            <li v-for="item in menuData" :key="item.id" class="mm-item">
              <a
                class="mm-text"
                :href="getRoute(item)"
                :target="
                  item.Redirect == 'Link' && item.Link ? '_blank' : '_self'
                "
                @click="item.Redirect == 'Tutorial' && (displayBasic = true)"
                >{{ item.Title }}</a
              >
              <div></div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="first-col layout-col span6 col">
      <div
        class="layout-block style-103632 clearfix"
        data-cid="129"
        id="content_177511"
      >
        <div class="content externalvideo">
          <div class="content-wrap">
            <header class="viewport-header">
              <a href="/">
                <img
                  v-if="logoData"
                  :src="smallMedia(logoData.data.attributes)"
                  alt="The Elixir School"
                  class="logo-main"
                  loading="lazy"
                />
                <img
                  v-else
                  src="bbk12e1-cdn.myschoolcdn.com/ftpimages/842/logo/elixir.png"
                  alt="The Elixir School"
                  class="logo-main"
                  loading="lazy"
                />
              </a>
            </header>
          </div>
        </div>
      </div>
    </div>

    <div v-if="menuData.length > 0" class="last-col layout-col span6 col">
      <div
        class="hidden-phone layout-block style-103614 clearfix"
        data-cid="389"
        id="content_177513"
      >
        <div class="content menu">
          <nav
            class="nav-menu menu-animate-true menu-action-hover menu-type-horizontal-flat menu-direction-down"
          >
            <ul class="menu-container level1">
              <li
                v-for="item in menuData"
                :key="item.id"
                class="menu-item menu-item-111827 l1-item level-1 my-dropdown"
              >
                <a
                  :href="getRoute(item)"
                  :target="
                    item.Redirect == 'Link' && item.Link ? '_blank' : '_self'
                  "
                  @click="item.Redirect == 'Tutorial' && (displayBasic = true)"
                  >{{ item.Title }}</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { smallMedia } from "@/utils/utils";

import LvDialog from "lightvue/dialog";

import axios from "axios";

export default {
  name: "Header",
  components: {
    LvDialog,
  },
  data() {
    return {
      displayBasic: false,
      menuOpen: false,
      logoLoading: false,
      logoData: null,
      dataLoading: false,
      menuData: [],
    };
  },
  created() {
    this.LoadLogo();
    this.LoadData();
  },
  methods: {
    smallMedia,
    getRoute(item) {
      if (item.Redirect == "Page" && item.Page) {
        return item.Page == "Home" ? "/" : `/${item.Page.toLowerCase()}`;
      } else if (item.Redirect == "Link" && item.Link) {
        return item.Link;
      } else {
        return "#";
      }
    },
    LoadLogo() {
      this.logoLoading = true;
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/logos?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            this.logoData = res.data.data[0].attributes.media;
          } else this.logoData = null;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.logoLoading = false));
    },
    LoadData() {
      this.dataLoading = true;
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/main-menus?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            this.menuData = res.data.data.map((el) => el.attributes.Item);
          } else this.menuData = [];
        })
        .catch((err) => console.log(err))
        .finally(() => (this.dataLoading = false));
    },
  },
};
</script>

<style scoped>
[class*="span"] {
  min-height: unset !important;
}
</style>
