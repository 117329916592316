<template>
  <div class="home">
    <FsLightbox
      :toggler="toggler"
      :sources="currentImages"
      :slide="slideIndex"
    />
    <!-- :onOpen="setSlide"
      :onClose="closeSlide" -->

    <LvDialog
      header="Elixir App"
      v-model="displayBasic"
      :style="{ maxWidth: '900px', width: '100%' }"
    >
      <div class="app-links">
        <div>
          <video playsinline="" autoplay controls class="app-video">
            <source
              type="video/mp4"
              src="@/assets/video/elixir-app-tutorial.mp4"
            />
          </video>
        </div>
        <a
          href="https://play.google.com/store/apps/details?id=com.myskool.elixir"
          target="_blank"
        >
          <img src="@/assets/images/playstore.svg" width="135" alt="" />
        </a>
        <a
          href="https://apps.apple.com/pk/app/elixir-school/id6503348432"
          target="_blank"
        >
          <img src="@/assets/images/apple.svg" width="135" alt="" />
        </a>
      </div>
    </LvDialog>
    <div v-if="dataLoading" class="custom-loader-wrapper">
      <div class="custom-loader"></div>
    </div>
    <div class="layout" id="layout_14909" v-else-if="pageData">
      <div
        v-if="pageData.Video"
        class="region layout-region video-section"
        id="layout_region_0"
      >
        <!-- <div class="video-overlay"></div> -->
        <div class="full-width">
          <div class="style-105031 layout-row row-fluid">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="129"
                id="content_177612"
              >
                <div class="content externalvideo">
                  <div class="content-wrap bgvid-dimensions">
                    <video
                      playsinline=""
                      autoplay=""
                      muted=""
                      loop=""
                      id="bgvid"
                      style="width: 100%"
                    >
                      <source
                        type="video/mp4"
                        :src="smallMedia(pageData.Video.video.data.attributes)"
                      />
                      <!-- src="@/assets/video/video-final.mp4" -->
                      <!-- <source type="video/mp4" src="https://cdn.cloudious.net/myskool-elixir-cover.mp4" /> -->
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="subData.length > 0" class="layout-row row-fluid">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="hidden-phone layout-block style-102833 clearfix"
                data-cid="389"
                id="content_177515"
              >
                <div class="content menu">
                  <nav
                    class="nav-menu menu-animate-true menu-action-hover menu-type-horizontal-flat menu-direction-down"
                  >
                    <ul class="menu-container level1">
                      <li
                        v-for="item in subData"
                        :key="item.id"
                        class="menu-item menu-item-111433 l1-item level-1"
                      >
                        <a
                          :href="getRoute(item)"
                          :target="
                            item.Redirect == 'Link' && item.Link
                              ? '_blank'
                              : '_self'
                          "
                          @click="
                            item.Redirect == 'Tutorial' && (displayBasic = true)
                          "
                          >{{ item.Title }}</a
                        >
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="pageData.OurFeatures.length > 0"
          class="style-104895 region layout-region marquee-section marque-video"
        >
          <div class="full-width">
            <div class="layout-row row-fluid marquee-wrapper">
              <div v-for="i in 3" :key="i" class="marquee-content scroll">
                <div
                  v-for="item in pageData.OurFeatures"
                  :key="item.id"
                  class="marquee-item"
                >
                  <img
                    :src="smallMedia(item.Icon.data.attributes)"
                    alt=""
                    loading="lazy"
                  />
                  <p class="icon-text">{{ item.Details }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!pageData.Video && pageData.OurFeatures.length > 0"
        class="style-104895 region layout-region marquee-section marque-video"
        style="position: static"
      >
        <div class="full-width">
          <div class="layout-row row-fluid marquee-wrapper">
            <div v-for="i in 3" :key="3" class="marquee-content scroll">
              <div
                v-for="item in pageData.OurFeatures"
                :key="3"
                class="marquee-item"
              >
                <img
                  :src="smallMedia(item.Icon.data.attributes)"
                  alt=""
                  loading="lazy"
                />
                <p class="icon-text">{{ item.Details }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="style-105032 region layout-region" id="layout_region_2">
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177997"
              >
                <div
                  class="layout_block_177997 region page-region"
                  id="page_region_175238"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- stories -->
      <div v-if="pageData.Stories.length > 0" class="stories-section">
        <div class="container">
          <Swiper :slides-per-view="'auto'" :space-between="20">
            <SwiperSlide v-for="(item, ind) in pageData.Stories" :key="ind">
              <a
                class="stories-item-wrapper"
                @click="openStory(item.Gallery.data, ind)"
              >
                <div class="stories-item">
                  <img
                    :src="smallMedia(item.CoverImage.data.attributes)"
                    alt=""
                    class="stories-img"
                    loading="lazy"
                  />
                </div>
                <p class="stories-title">{{ item.Title }}</p>
              </a>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div
        v-if="pageData.StudyAtElixir"
        class="style-105032 region layout-region"
        id="layout_region_1"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177611"
              >
                <div
                  class="layout_block_177611 region page-region"
                  id="page_region_174824"
                >
                  <div class="style-103676 page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img
                                src="@/assets/images/studyHeading.png"
                                alt=""
                              /> -->
                              <span class="heading-black">
                                {{ pageData.StudyAtElixir.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.StudyAtElixir.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col page-col span6 col"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block style-103672 clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a
                                  href="#"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    :src="
                                      smallMedia(
                                        pageData.StudyAtElixir.Picture1.data
                                          .attributes
                                      )
                                    "
                                    class="link-image"
                                    alt="Student 1"
                                    loading="lazy"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-col span6 col"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block style-103672 clearfix"
                        data-cid="2"
                        id="content_3122165"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a
                                  href="#"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    :src="
                                      smallMedia(
                                        pageData.StudyAtElixir.Picture2.data
                                          .attributes
                                      )
                                    "
                                    class="link-image"
                                    alt="Student 2"
                                    loading="lazy"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-col span6 col"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block style-103672 clearfix"
                        data-cid="2"
                        id="content_3122166"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a
                                  href="#"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    :src="
                                      smallMedia(
                                        pageData.StudyAtElixir.Picture3.data
                                          .attributes
                                      )
                                    "
                                    class="link-image"
                                    alt="Student 3"
                                    loading="lazy"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="last-col page-col span6 col"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block style-103672 clearfix"
                        data-cid="2"
                        id="content_3122167"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <a
                                  href="#"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    :src="
                                      smallMedia(
                                        pageData.StudyAtElixir.Picture4.data
                                          .attributes
                                      )
                                    "
                                    class="link-image"
                                    alt="Student 4"
                                    loading="lazy"
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.ElixirVillage"
        class="style-103644 region layout-region"
        id="layout_region_3"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="style-103676 page-row row">
                    <div
                      class="first-col page-col span16 col other-video"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <div
                        class="page-block style-103675 clearfix"
                        data-cid="1"
                        id="content_3120486"
                      >
                        <div class="content text">
                          <div class="content-wrap bgvid-dimensions">
                            <video
                              playsinline=""
                              autoplay=""
                              muted=""
                              loop=""
                              id="bgvid"
                              style="width: 100%"
                            >
                              <source
                                type="video/mp4"
                                :src="
                                  smallMedia(
                                    pageData.ElixirVillage.Video.data.attributes
                                  )
                                "
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="last-col page-col span8 col"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div
                        class="page-block style-103675 clearfix"
                        data-cid="1"
                        id="content_3121855"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img
                                src="@/assets/images/villagetitle.png"
                                alt=""
                              /> -->
                              <span class="heading-black side-heading">
                                {{ pageData.ElixirVillage.HeadingBlack }}
                              </span>
                              <span class="heading-red side-heading">
                                {{ pageData.ElixirVillage.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="pageData.ElixirVillage.Details.length > 0"
                        class="page-block style-103675 clearfix"
                        data-cid="2"
                        id="content_3118675"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li
                                v-for="item in pageData.ElixirVillage.Details"
                                :key="item.id"
                                class="group"
                              >
                                <a
                                  :href="item.url ? item.url : '#'"
                                  class="custom-link-photo style-no-select links-imglink"
                                >
                                  <img
                                    :src="smallMedia(item.Icon.data.attributes)"
                                    class="link-image"
                                    alt=""
                                    loading="lazy"
                                  />
                                </a>
                                <a
                                  class="custom-title links-urllink"
                                  :href="item.url ? item.url : '#'"
                                  >{{ item.Title }}</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.CelebratingIndividuals"
        class="style-105032 region layout-region indi-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="style-103676 page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="@/assets/images/celeb.png" alt="" /> -->
                              <span class="heading-black">
                                {{
                                  pageData.CelebratingIndividuals.HeadingBlack
                                }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.CelebratingIndividuals.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col style-103700 page-col span12 col"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <div
                              class="my-content"
                              v-html="
                                convertSpecialChars(
                                  pageData.CelebratingIndividuals.Details
                                )
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div class="">
                        <img
                          :src="
                            smallMedia(
                              pageData.CelebratingIndividuals.Image1.data
                                .attributes
                            )
                          "
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div class="">
                        <img
                          :src="
                            smallMedia(
                              pageData.CelebratingIndividuals.Image2.data
                                .attributes
                            )
                          "
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div class="">
                        <img
                          :src="
                            smallMedia(
                              pageData.CelebratingIndividuals.Image3.data
                                .attributes
                            )
                          "
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div class="">
                        <img
                          :src="
                            smallMedia(
                              pageData.CelebratingIndividuals.Image4.data
                                .attributes
                            )
                          "
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div class="">
                        <img
                          :src="
                            smallMedia(
                              pageData.CelebratingIndividuals.Image5.data
                                .attributes
                            )
                          "
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div class="">
                        <img
                          :src="
                            smallMedia(
                              pageData.CelebratingIndividuals.Image6.data
                                .attributes
                            )
                          "
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div class="">
                        <img
                          :src="
                            smallMedia(
                              pageData.CelebratingIndividuals.Image7.data
                                .attributes
                            )
                          "
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div class="">
                        <img
                          :src="
                            smallMedia(
                              pageData.CelebratingIndividuals.Image8.data
                                .attributes
                            )
                          "
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div class="">
                        <img
                          :src="
                            smallMedia(
                              pageData.CelebratingIndividuals.Image9.data
                                .attributes
                            )
                          "
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.OurAchievements.length > 0"
        class="style-104895 region layout-region marquee-section other-icons"
      >
        <div class="full-width">
          <div class="layout-row row-fluid marquee-wrapper">
            <div
              v-for="i in 3"
              :key="i"
              class="marquee-content scroll reverse-scroll"
            >
              <div
                v-for="item in pageData.OurAchievements"
                :key="item.id"
                class="marquee-item"
              >
                <img
                  :src="smallMedia(item.Icon.data.attributes)"
                  alt=""
                  loading="lazy"
                />
                <p class="icon-text">{{ item.Details }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.OurFacilities"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="style-103676 page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img
                                src="@/assets/images/facilityHeading.png"
                                alt=""
                              /> -->
                              <span class="heading-black">
                                {{ pageData.OurFacilities.Heading }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="my-content"
                        v-html="
                          convertSpecialChars(pageData.OurFacilities.Details)
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.OurPrograms.length > 0"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div
                    v-for="item in pageData.OurPrograms"
                    :key="item.id"
                    class="page-row row"
                  >
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper single-image-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                      :class="{ 'image-right': item.Side == 'Right' }"
                    >
                      <div class="">
                        <img
                          :src="smallMedia(item.Image.data.attributes)"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col single-content-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <h2 class="my-heading">{{ item.Heading }}</h2>
                            <div
                              class="my-content"
                              v-html="convertSpecialChars(item.Details)"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.TehzeebProgram"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row" style="margin: 0">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="@/assets/images/tehzeeb.png" alt="" /> -->
                              <span class="heading-black">
                                {{ pageData.TehzeebProgram.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.TehzeebProgram.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-row row">
                    <div
                      class="first-col style-103700 page-col span12 col indi-images-wrapper single-image-wrapper"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <div class="">
                        <img
                          :src="
                            smallMedia(
                              pageData.TehzeebProgram.Image.data.attributes
                            )
                          "
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div
                      class="first-col style-103700 page-col span12 col single-content-wrapper"
                      data-aos="zoom-in"
                      data-aos-duration="900"
                    >
                      <div
                        class="page-block clearfix"
                        data-cid="2"
                        id="content_3122164"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <div
                              class="my-content"
                              v-html="
                                convertSpecialChars(
                                  pageData.TehzeebProgram.Details
                                )
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.PhysicalEducation"
        class="style-105032 region layout-region indi-section single-section"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177570"
              >
                <div
                  class="layout_block_177570 region page-region"
                  id="page_region_174764"
                >
                  <div class="page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3121857"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="@/assets/images/physical.png" alt="" /> -->
                              <span class="heading-black">
                                {{ pageData.PhysicalEducation.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.PhysicalEducation.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="my-content"
                        v-html="
                          convertSpecialChars(
                            pageData.PhysicalEducation.Details
                          )
                        "
                      ></div>
                    </div>
                  </div>
                  <div
                    v-if="pageData.PhysicalEducation.Items.length > 0"
                    class="row physical-wrapper"
                  >
                    <div
                      v-for="item in pageData.PhysicalEducation.Items"
                      :key="item.id"
                      class="first-col page-col span12 col"
                      data-aos="flip-right"
                      data-aos-duration="1200"
                    >
                      <div class="">
                        <h2 class="my-heading">{{ item.Heading }}</h2>
                        <div
                          class="my-content"
                          v-html="convertSpecialChars(item.Details)"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="pageData.ElixirHighlights"
        class="style-105032 region layout-region"
        id="layout_region_4"
      >
        <div class="container">
          <div class="layout-row row">
            <div class="last-col first-col layout-col span24 col">
              <div
                class="layout-block clearfix"
                data-cid="390"
                id="content_177517"
              >
                <div
                  class="layout_block_177517 region page-region"
                  id="page_region_174606"
                >
                  <div class="style-103676 page-row row">
                    <div class="last-col first-col page-col span24 col">
                      <div
                        class="page-block style-105241 clearfix"
                        data-cid="1"
                        id="content_3119619"
                      >
                        <div class="content text">
                          <div class="content-wrap">
                            <div class="photos">
                              <!-- <img src="@/assets/images/highlight.png" alt="" /> -->
                              <span class="heading-black">
                                {{ pageData.ElixirHighlights.HeadingBlack }}
                              </span>
                              <span class="heading-red">
                                {{ pageData.ElixirHighlights.HeadingRed }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="pageData.ElixirHighlights.Items.length > 0"
                    class="page-row row"
                  >
                    <div
                      v-for="item in pageData.ElixirHighlights.Items"
                      :key="item.id"
                      class="first-col page-col span4 col"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      <div
                        class="page-block style-105242 clearfix"
                        data-cid="2"
                        id="content_3118721"
                      >
                        <div class="content links">
                          <div class="content-wrap">
                            <ul class="no-bullets">
                              <li class="group">
                                <img
                                  :src="smallMedia(item.Image.data.attributes)"
                                  class="link-image"
                                  alt=""
                                  loading="lazy"
                                />
                                <div class="highlight-overlay"></div>
                                <div class="highlight-content">
                                  <h3>{{ item.Title }}</h3>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- end -->
    </div>
    <div v-else class="custom-loader-wrapper">
      <h1>Page Not Found 🕵🏻‍♀️</h1>
      <p>
        Oops! 😖 The page you're looking for does not exist or have been
        temporarily unavailable.
      </p>
    </div>
  </div>
</template>
<script>
import { convertSpecialChars, smallMedia } from "@/utils/utils";

import FsLightbox from "fslightbox-vue";
import LvDialog from "lightvue/dialog";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

import axios from "axios";

export default {
  name: "HomeNew",
  components: {
    FsLightbox,
    LvDialog,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      pageData: null,
      dataLoading: false,
      toggler: false,
      currentImages: [],
      slideIndex: 1,
      intervalID: null,
      nextBtn: null,
      subData: [],
      displayBasic: false,
    };
  },
  created() {
    this.LoadData();
    this.LoadSub();
  },
  methods: {
    convertSpecialChars,
    smallMedia,
    getRoute(item) {
      if (item.Redirect == "Page" && item.Page) {
        return item.Page == "Home" ? "/" : `/${item.Page.toLowerCase()}`;
      } else if (item.Redirect == "Link" && item.Link) {
        return item.Link;
      } else {
        return "#";
      }
    },
    openStory(images, ind) {
      this.slideIndex = 1;
      this.currentImages = images.map((el) => smallMedia(el.attributes));
      this.toggler = !this.toggler;
      // console.log(this.currentImages);
    },
    setSlide() {
      let obj = document.querySelectorAll(
        ".fslightbox-absoluted.fslightbox-full-dimension.fslightbox-flex-centered"
      );
      // console.log(obj);
      if (obj.length == 1) {
        obj[0].style.transform = "unset";
      }
      if (obj.length > 1) {
        this.nextBtn = document.querySelector(
          ".fslightbox-slide-btn-next-container"
        );
        this.intervalID = setInterval(this.nextSlide, 3000);
        // console.log(this.nextBtn, this.intervalID);
      }
    },
    nextSlide() {
      this.nextBtn.click();
    },
    closeSlide() {
      if (this.intervalID) clearInterval(this.intervalID);
      this.intervalID = null;
      this.nextBtn = null;
    },
    LoadData() {
      this.dataLoading = true;
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/home-pages?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            this.pageData = res.data.data[0].attributes;
            if (this.pageData.Stories.length > 0) {
              this.pageData.Stories = this.pageData.Stories.sort((a, b) => {
                return a.Sequence - b.Sequence;
              });
            }
          } else this.pageData = null;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.dataLoading = false));
    },
    LoadSub() {
      let config = {
        method: "get",
        url: `${this.$store.state.domain}/sub-menus?populate=deep`,
        headers: {
          Authorization: this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.data.length > 0) {
            let obj = res.data.data.find(
              (el) => el.attributes.ShowOn == "Home"
            );
            if (obj) this.subData = obj.attributes.Item;
            else this.subData = [];
          } else this.subData = [];

          // console.log(this.subData);
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style>
.swiper-slide {
  width: 160px;
}
</style>
