<template>
  <div>
    <div class="full-width style-105126"></div>
    <div class="style-105032 region layout-region indi-section">
      <div class="container">
        <div class="layout-row row">
          <div class="last-col first-col layout-col span24 col">
            <div
              class="layout-block clearfix"
              data-cid="390"
              id="content_177570"
            >
              <div
                class="layout_block_177570 region page-region"
                id="page_region_174764"
              >
                <div class="page-row row">
                  <div class="last-col first-col page-col span24 col">
                    <div
                      class="page-block style-105241 clearfix"
                      data-cid="1"
                      id="content_3121857"
                    >
                      <div class="content text">
                        <div class="content-wrap">
                          <div class="photos">
                            <img
                              src="@/assets/images/aboutHeading.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="page-row row">
                  <div class="first-col page-col col">
                    <div
                      class="page-block clearfix"
                      data-cid="2"
                      id="content_3122164"
                    >
                      <div class="content">
                        <div class="content-wrap">
                          <div class="my-content">
                            We believe quality education cannot be confined to a
                            particular geography, rather should be widespread.
                            It is with this premise that we laid the foundation
                            of “The Elixir School in one of Karachis oldest and
                            well established communities - North Nazimabad. We
                            aim to provide best education and development
                            opportunities for students with a well rounded
                            curriculum based on the following themes:
                            <br /><br />
                            <ul>
                              <li>Environmental Questions</li>
                              <li>Sustainable Development</li>
                              <li>
                                Communication & Information Technology Skills
                              </li>
                              <li>
                                Etiquette, Character Building & Soft Skills
                              </li>
                              <li>Entrepreneurship</li>
                              <li>Science IRD, Health & Safety</li>
                            </ul>
                          </div>
                          <!-- <div class="my-content">
                            Welcome to The Elixir School, a premier educational
                            institution dedicated to fostering a culture of
                            academic excellence, creativity and critical
                            thinking. Located in the vibrant city of Karachi,
                            the school is committed to provide a holistic
                            learning experience that prepares students for
                            success in an increasingly complex and connected
                            world.
                            <br /><br />
                            At Elixir, we believe that education should be a
                            transformative experience that empowers students to
                            become active learners, innovative thinkers and
                            responsible global citizens. Our experienced and
                            highly qualified faculty is passionate about
                            teaching and mentoring powered by dedication to
                            create an inclusive learning environment that
                            encourage students to explore, discover and grow!
                            <br /><br />
                            Our curriculum is carefully designed to promote
                            intellectual curiosity, creativity and critical
                            thinking and is aligned with latest research and
                            best practices in education.
                            <br /><br />
                            We offer a varied range of subjects and programmes
                            that cater to the diverse needs and interests of our
                            students, from science and mathematics to language
                            and arts. Our team is committed to excellence in all
                            aspects of our operations and strive to create a
                            community of learners passionate about knowledge,
                            innovation and service.
                            <br /><br />
                            Join us on this exciting journey of discovery and
                            learning. Become a part of the Elixir community
                            today!
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="style-105032 region layout-region indi-section">
      <div class="container">
        <div class="layout-row row">
          <div class="last-col first-col layout-col span24 col">
            <div
              class="layout-block clearfix"
              data-cid="390"
              id="content_177570"
            >
              <div
                class="layout_block_177570 region page-region"
                id="page_region_174764"
              >
                <div class="page-row row">
                  <div class="last-col first-col page-col span24 col">
                    <div
                      class="page-block style-105241 clearfix"
                      data-cid="1"
                      id="content_3121857"
                    >
                      <div class="content text">
                        <div class="content-wrap">
                          <div class="photos">
                            <img src="@/assets/images/vision.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="page-row row">
                  <div
                    class="first-col page-col span12 col"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div
                      class="page-block style-103675 clearfix"
                      data-cid="1"
                      id="content_3120486"
                    >
                      <div class="content text">
                        <div class="content-wrap">
                          <div class="photos">
                            <img
                              class="center-content-image"
                              src="@/assets/images/visionbg.jpeg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="first-col page-col span12 col center-content-wrapper"
                    data-aos="flip-right"
                    data-aos-duration="1200"
                  >
                    <div
                      class="page-block clearfix"
                      data-cid="2"
                      id="content_3122164"
                    >
                      <div class="content">
                        <div class="center-content set-margin">
                          <div class="my-content">
                            To develop a holistic and integrated learning
                            institution from early childhood to tertiary level
                            that produces rounded individuals who contribute
                            positively to society and environment.
                          </div>
                          <!-- <h1 class="quote-heading">
                            “A Thinking Institution. For Global Leaders.”
                          </h1>
                          <div class="my-content">
                            A thinking institution that purposefully re-defines
                            education and consistently transforms the lives of
                            individuals and communities, through learning,
                            compassion and innovation.
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="style-105032 region layout-region indi-section">
      <div class="container">
        <div class="layout-row row">
          <div class="last-col first-col layout-col span24 col">
            <div
              class="layout-block clearfix"
              data-cid="390"
              id="content_177570"
            >
              <div
                class="layout_block_177570 region page-region"
                id="page_region_174764"
              >
                <div class="page-row row">
                  <div class="last-col first-col page-col span24 col">
                    <div
                      class="page-block style-105241 clearfix"
                      data-cid="1"
                      id="content_3121857"
                    >
                      <div class="content text">
                        <div class="content-wrap">
                          <div class="photos">
                            <img src="@/assets/images/mission.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="page-row row">
                  <div
                    class="first-col page-col span12 col center-content-wrapper"
                    data-aos="flip-right"
                    data-aos-duration="1200"
                  >
                    <div class="center-content">
                      <div class="my-content">
                        Our mission is to inculcate a questioning mindset in
                        students, to tap into student's multiple intelligence
                        via integrated curriculum that fosters love for
                        learning, nature, diverse cultures, foreign languages
                        and extra-curricular activities. Our students actively
                        seek knowledge and solutions backed by strong values of
                        honesty, hard work and harmony with the environment and
                        community.
                      </div>
                      <!-- <h1 class="quote-heading">“Let’s. Strive. Success.”</h1>
                      <div class="my-content">
                        To create and sustain an inspirational ecosystem, where
                        each learner undertakes unparalleled educational
                        journeys that are intellectually, personally and
                        socially transformative as they realize their God-gifted
                        potentials towards serving and success.
                      </div> -->
                    </div>
                  </div>
                  <div
                    class="first-col page-col span12 col"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div
                      class="page-block style-103675 clearfix"
                      data-cid="1"
                      id="content_3120486"
                    >
                      <div class="content text">
                        <div class="content-wrap">
                          <div class="photos">
                            <img
                              src="@/assets/images/missionbg.jpg"
                              class="center-content-image"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="style-103644 region layout-region" id="layout_region_3">
      <div class="container">
        <div class="layout-row row">
          <div class="last-col first-col layout-col span24 col">
            <div
              class="layout-block clearfix"
              data-cid="390"
              id="content_177570"
            >
              <div
                class="layout_block_177570 region page-region"
                id="page_region_174764"
              >
                <div class="style-103676 page-row row">
                  <div
                    class="first-col page-col span16 col other-video"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div
                      class="page-block style-103675 clearfix"
                      data-cid="1"
                      id="content_3120486"
                    >
                      <div class="content text">
                        <div class="content-wrap bgvid-dimensions">
                          <!-- <div class="photos">
                              <img
                                src="@/assets/images/school.jpg"
                                alt=""
                                style="border-radius: 10px"
                              />
                            </div> -->
                          <!-- autoplay=""
                            muted=""
                            loop="" -->
                          <video
                            playsinline=""
                            controls
                            controlslist="nodownload"
                            id="bgvid"
                            style="width: 100%"
                          >
                            <source
                              type="video/mp4"
                              src="@/assets/video/video-fb.mp4"
                            />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="last-col page-col span8 col center-content-wrapper"
                    data-aos="flip-right"
                    data-aos-duration="1200"
                  >
                    <div
                      class="page-block style-103675 clearfix"
                      data-cid="1"
                      id="content_3121855"
                    >
                      <div class="content text">
                        <div class="content-wrap">
                          <div class="photos">
                            <img
                              src="@/assets/images/staffHeading.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div class="my-content">
                        Embark on an engaging journey delving into the core of
                        our school community unveiling our ethos through
                        heartfelt reflections from School's Leadership and
                        dedicated Faculty Members - the team behind the passion,
                        vision and commitment that propels our academic
                        endeavours forward.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="style-105032 region layout-region indi-section">
      <div class="container">
        <div class="layout-row row">
          <div class="last-col first-col layout-col span24 col">
            <div
              class="layout-block clearfix"
              data-cid="390"
              id="content_177570"
            >
              <div
                class="layout_block_177570 region page-region"
                id="page_region_174764"
              >
                <div class="page-row row">
                  <div class="last-col first-col page-col span24 col">
                    <div
                      class="page-block style-105241 clearfix"
                      data-cid="1"
                      id="content_3121857"
                    >
                      <div class="content text">
                        <div class="content-wrap">
                          <div class="photos">
                            <img src="@/assets/images/value.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="page-row row"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div class="my-content">
                    Admissions are offered from Nursery to Grade 10 (a test and
                    interview are pre-requisite to admissions). Forms are
                    available at campus. We truly value trust envisaged by
                    parents upon us and offer 10% discount to siblings in
                    monthly fee. For futher informa- tion, please call us and
                    schedule campus visit.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="style-105032 region layout-region indi-section">
      <div class="container">
        <div class="layout-row row">
          <div class="last-col first-col layout-col span24 col">
            <div
              class="layout-block clearfix"
              data-cid="390"
              id="content_177570"
            >
              <div
                class="layout_block_177570 region page-region"
                id="page_region_174764"
              >
                <div class="page-row row">
                  <div class="last-col first-col page-col span24 col">
                    <div
                      class="page-block style-105241 clearfix"
                      data-cid="1"
                      id="content_3121857"
                    >
                      <div class="content text">
                        <div class="content-wrap">
                          <div class="photos">
                            <img src="@/assets/images/qec.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="page-row row"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div class="my-content">
                    At Elixir, our Quality Enhancement Cell (QEC) ensures
                    eminent teaching and learning at school, develops curriculum
                    and syllabi, and helps in maintaining high standards. We
                    encourage our teachers to constantly improve teaching skills
                    by attending workshops, seminars, trainings, conferences
                    etc.
                    <br /><br />
                    Our QEC performs review of quality standards and ensures
                    elevated standard of teaching and leaming in each subject,
                    clearly defining parameters in accordance with reference to
                    the reviews provided by stake holders, ie Students, Parents
                    and Teachers.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="style-104895">
      <div class="container">
        <div class="form-container">
          <div class="form-wrapper">
            <form @submit.prevent="formSubmit">
              <h2 class="form-heading">Register Your Interest</h2>

              <div class="form-group-wrapper">
                <div class="form-group">
                  <label class="form-label" for="name">Name</label>
                  <input
                    class="form-control"
                    type="text"
                    name="name"
                    id="name"
                    v-model.trim="mailObj.name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label class="form-label" for="email">Email</label>
                  <input
                    class="form-control"
                    type="email"
                    name="email"
                    id="email"
                    v-model="mailObj.email"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="phone">Phone</label>
                <input
                  class="form-control"
                  type="number"
                  name="phone"
                  id="phone"
                  v-model="mailObj.phone"
                  required
                />
              </div>
              <div class="form-group">
                <label class="form-label" for="msg">Message</label>
                <textarea
                  class="form-control"
                  name="msg"
                  id="msg"
                  rows="4"
                  v-model.trim="mailObj.message"
                  required
                />
              </div>
              <div class="form-button-wrapper">
                <button
                  type="submit"
                  class="button-primary"
                  :disabled="request"
                >
                  <div
                    v-if="request"
                    class="custom-mini-loader"
                    style="margin: auto"
                  ></div>
                  <span v-else>Send</span>
                </button>
              </div>
            </form>
          </div>
          <div class="form-info">
            <h2 class="form-info-heading">Speak to the Admission Counselor</h2>
            <hr />
            <div class="form-info-item">
              <img src="@/assets/images/arrow-red.png" alt="" />Speak to the
              admission advisor today.
            </div>
            <div class="form-info-item">
              <img src="@/assets/images/arrow-red.png" alt="" />Registrations
              are now open for Academic Session 2024-25.
            </div>
            <div class="form-info-item">
              <img src="@/assets/images/arrow-red.png" alt="" />Call and book an
              appointment by speaking to our Admission Counselor between 9:00 am
              to 1:00 pm (Monday to Saturday).
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      videoUrl: require("@/assets/video/video-fb.mp4"),
      mailObj: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      request: false,
      // receiver: "aseefjawaid123@gmail.com",
      receiver: "officesupport@theelixirschools.com",
    };
  },
  methods: {
    formSubmit() {
      // console.log(this.mailObj);
      this.request = true;

      const config = {
        method: "post",
        url: "https://mail.appick.io/cloudious",
        data: {
          emailTo: this.receiver,
          subject: "The Elixir School | Contact",
          text: `Name: ${this.mailObj.name}\nEmail: ${this.mailObj.email}\nPhone: ${this.mailObj.phone}\nMessage: ${this.mailObj.message}`,
          html: "",
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res);
          if (res.data.status == "success") {
            this.$toast.success("Message sent successfully!");
            this.mailObj = {
              name: "",
              email: "",
              phone: "",
              message: "",
            };
          } else {
            this.$toast.error("Something went wrong!");
          }
        })
        .catch((err) => {
          // console.log(err);
          this.toast.error("Something went wrong!");
        })
        .finally(() => (this.request = false));
    },
  },
};
</script>
<style></style>
